
import { defineComponent, onMounted, ref } from "vue";
import type { PropType } from "vue";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

export default defineComponent({
  name: "plyrAudio",

  props: {
    src: String,
    controls: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => ["play", "progress", "current-time", "mute", "volume"]
    }
  },

  setup(props) {
    const audio = ref<HTMLAudioElement | null>(null);

    onMounted(() => {
      new Plyr(audio.value!, {
        controls: props.controls,
        debug: process.env.VUE_APP_MODE !== "production",
        iconUrl: require("plyr/dist/plyr.svg"),
        blankVideo: require("@/assets/plyr/blank.mp4")
      });
    });

    return { audio };
  }
});
